import React from "react"
import { Router } from "@reach/router"
import TemplateWrapper from "../components/Layout"
import { ActionPage } from "../app/pages/action"

const App = () => {
  return (
    <TemplateWrapper>
      <Router basepath="/app">
        <ActionPage path='/action' />
        <ActionPage path='/' />
        {/* <Profile path="/profile" />
        <Details path="/details" />
        <Login path="/login" />
        <Default path="/" /> */}
      </Router>
    </TemplateWrapper>
  )
}

export default App