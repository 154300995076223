import React, { Fragment, useEffect, useState } from 'react'
import * as queryString from 'query-string';
import ResetPassword from '../components/reset-password';
// import Layout from '../components/Layout'
import useFirebase from '../../hooks/use-firebase';

const RESET = 'resetPassword';
// const RECOVER = 'recoverEmail';
// const VERIFY = 'verifyEmail';


export const ActionPage = (props) => {
  const queryParams = queryString.parse(props.location.search);
  const firebase = useFirebase();
  const [auth, setAuth] = useState(null)

  // console.log(queryParams)
  const {mode, oobCode} = queryParams

  useEffect(() => {
    if (!firebase) return;
    // return firebase.auth().onAuthStateChanged((user) => {
    //   if (user) setIsLoggedIn(true)
    //   else setIsLoggedIn(false)
    // });
    setAuth(firebase.auth())
  }, [firebase]);

  const getComponent = (mode) => {
    console.log('firebase', firebase)
    if (!firebase) return <Loading />
    switch (mode) {
      case RESET:
        return <ResetPassword auth={auth} oobCode={oobCode} />
      default:
        return <Error />;
    }
  }

  return (
    <section className="section">
      <div className="container">
        <div className="content">
          {getComponent(mode)}
        </div>
      </div>
    </section>
  )
}

const Error = () => {
  return (
    <Fragment>
      <h1>Error de Navegación</h1>
      <p>Al parecer has llegado a esta página por error.</p>
      <p>Puedes seguir navegando en nuestro sitio web sin problemas.</p>
      <p>Si fuiste enviado a esta página por un link en un correo electrónico enviado por nosotros, debe haber un error de nuestra parte.</p>
    </Fragment>
  )
}

const Loading = () => <center><progress className="progress is-small is-primary" max="100">20%</progress></center>
