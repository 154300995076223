import firebase from 'firebase/app';
import 'firebase/auth'; // importing the auth module as an example

// Firebase web config
const config = {
  apiKey: "AIzaSyBpmLzEqCNMLG5HEDeFm0HV1Tg_k2HMuhk",
  authDomain: "psicosmart-5555f.firebaseapp.com",
  databaseURL: "https://psicosmart-5555f.firebaseio.com",
  projectId: "psicosmart-5555f",
  storageBucket: "psicosmart-5555f.appspot.com",
  messagingSenderId: "1012830591806",
  appId: "1:1012830591806:web:bc75edefd2bb50a5726a6f"
  // measurementId: '',
}

let instance = null;

export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    instance = firebase.initializeApp(config);
    return instance;
  }

  return null;
}