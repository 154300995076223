import React, { Fragment, useState, useEffect } from "react";
import { Link } from 'gatsby'

const ResetPassword = ({
  auth,
  oobCode,
}) => {
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [working, setWorking] = useState(false)
  const [estado, setEstado] = useState('loading')
  const [email, setEmail] = useState('')
  const [valid, setValid] = useState(false)
  const [error, setError] = useState('')

  const handleChange = e => {
    const str = e.target.value
    setPassword(str)
  };
  const handleCheck = e => {
    const str = e.target.value
    setPasswordCheck(str)
  };
  const clear = () => {
    setPassword('')
    setPasswordCheck('')
  }

  const validatePass = () => {
    const passMinLength = 6
    if (password.length < passMinLength) setError('La contraseña debe contener al menos 6 caracteres')
    else if (password !== passwordCheck) setError('Las contraseñas deben coincidir')
    else setError('')
    setValid((password.length >= passMinLength) && (password === passwordCheck))
  }

  useEffect(() => {
    validatePass()
  }, [passwordCheck, password])

  useEffect(() => {
    if (!oobCode) return
    if (auth) auth.verifyPasswordResetCode(oobCode)
      .then(resEmail => {
        setEmail(resEmail)
        setEstado('valid')
      })
      .catch((_) => {
        setEstado('invalid')
      })
  }, [auth])

  const establecerPassword = () => {
    setWorking(true)
    auth.confirmPasswordReset(oobCode, password)
      .then((res) => {
        setEstado('completed')
        setWorking(false)
      })
      .catch((err) => {
        setWorking(false)
        switch (err.code) {
          case 'auth/weak-password':
            setError('La contraseña debe contener al menos 6 caracteres.')
            break;
          default:
            setError('Ha ocurrido un error inesperado. Intente de nuevo más tarde.')
            break;
        }
        console.log(err)
      })
  }

  const getComponent = (estado) => {
    switch (estado) {
      case 'invalid':
        return <InvalidToken />
      case 'valid':
        return <PassForm
          email={email}
          password={password}
          passwordCheck={passwordCheck}
          clear={clear}
          handleCheck={handleCheck}
          handleChange={handleChange}
          working={working}
          valid={valid}
          establecer={establecerPassword}
          error={error}
        />
      case 'completed':
        return <Completed email={email} />
      default:
        return <Loading />;
    }
  }

  return (
    <Fragment>
      {getComponent(estado)}
    </Fragment>
  );
};


const PassForm = ({
  email,
  password,
  passwordCheck,
  clear,
  working,
  handleChange,
  handleCheck,
  valid,
  establecer,
  error
}) => {
  return (
    <Fragment>
      <h1>Establecer una nueva contraseña</h1>
      <h3>Cuenta: {email}</h3>
      <p>
        Has sido dirigido a esta página para poder establecer tu contraseña, ya
        sea porque lo solicitaste o porque tu cuenta ha sido recién creada.
      </p>
      <div style={{ maxWidth: 400, marginLeft: "auto", marginRight: "auto" }}>
        <p>Escribe tu nueva contraseña</p>
        <div className="field">
          {/* <p className="control has-icons-left"> */}
          <p className="control">
            <input
              className="input"
              onChange={handleChange}
              value={password}
              type="password"
              placeholder="Nueva contraseña"
            />
            {/* <span className="icon is-small is-left">
              <i className="fas fa-lock"></i>
            </span> */}
          </p>
        </div>
        <br />
        <p>Vuelve a escribir tu nueva contraseña</p>
        <div className="field">
          {/* <p className="control has-icons-left"> */}
          <p className="control">
            <input
              className="input"
              onChange={handleCheck}
              value={passwordCheck}
              type="password"
              placeholder="Confirmar contraseña"
            />
            {/* <span className="icon is-small is-left">
              <i className="fas fa-lock"></i>
            </span> */}
          </p>
        </div>
        {error !== '' ? <p style={{color: 'red'}}>{error}</p> : <p>&nbsp;</p>}
        <center>
          <button className="button"
            onClick={clear}
          >Borrar</button>
          <button disabled={working || !valid} onClick={establecer} style={{marginLeft: 8}} className="button is-primary">Establecer</button>
        </center>
      </div>
    </Fragment>
  )
}

const InvalidToken = () => {
  return (
    <Fragment>
      <h1>Link Inválido</h1>
      <p>
        El enlace que te trajo aquí ha expirado o no es válido. Para reestablecer tu contraseña, ingresa a la aplicación PsicoTest e intenta nuevamente.
      </p>
    </Fragment>
  )
}

const Completed = ({email}) => {
  return (
    <Fragment>
      <h1>Contraseña establecida con éxito</h1>
      <p>Se ha establecido su nueva contraseña para el usuario <strong>{email}</strong>.</p>
      <p>Vuelva a la aplicación IntelTest y acceda con sus credenciales.</p>
      <p>Si aún no lo ha hecho, puede descargar el software en <Link to="/descargas">Este Enlace</Link>.</p>
      <p>Si tiene dudas de cómo usarlo, puede revisar una guía en <Link to="/blog/2020-03-07-usando-el-software-intel-correcciones">Este Artículo</Link>.</p>
    </Fragment>
  )
}

const Loading = () => <center><progress className="progress is-small is-primary" max="100">20%</progress></center>

export default ResetPassword;
